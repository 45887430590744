.conditional_formula {
  position: relative;
  margin-top: -25px;

  .formula_container {
    padding-top: 10px;
    padding-bottom: 10px;

    &:not(.last_logic) {
      border-bottom: 1px solid #B2B2B2;
    }
  }

  .add_button {
    border-left: 0px;
    border-right: 0px;
  }

  .delete_button {
    left: 35% !important;
    color: #4f4f4f;
    background-color: #fff;
    border: 1px solid #dadada;
    padding: 8px;
    // border-radius: 6px;

    &:hover {
      color: #f14668;
      border: 1px solid #f14668;
    }

    .fas {
      font-size: 11px !important;
    }
  }

  .even {
    background-color: #EeEeEe;

    .rule {
      border: 1px solid #dadada;
    }
  }

  span.content {
    margin-left: 15px;
    width: 245px;
    position: relative;
    top: -2px;
  }

  .formula_container>.input_editor {
    margin-left: 10px;
    width: 275px;
    padding: 4px !important;
    font-size: 12px;
  }

  .queryBuilder {
    padding: 5px;
    margin-top: -20px;
    font-size: 12px;

    select:not(.custom_select) {
      font-size: 12px;
      border: 1px solid #DBDBDB;
      border-radius: 4px;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-color: #fff;
      padding-right: 20px !important;
      background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='angle-down' class='svg-inline--fa fa-angle-down fa-w-10' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='currentColor' d='M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z'%3E%3C/path%3E%3C/svg%3E");
      background-repeat: no-repeat, repeat;
      background-position: right .7em top 50%, 0 0;
      background-size: .65em auto, 100%;
    }

    .rule-fields,
    .rule-operators,
    .rule-value,
    .rule-remove {
      margin-top: 5px;
      margin-bottom: 5px;
      margin-right: 10px !important;
      padding: 5px
    }

    .rule {
      position: relative !important;
      padding: 10px;
      margin-top: 10px;
      border: 1px solid #dedede;
      padding-bottom: 10px;
      // height: 140px;

      button {
        width: 245px;
        padding: 10px !important;
        height: 30px !important;
      }
    }

    button.conditional_logic {
      padding: 10px !important;
    }

    .ruleGroup-addRule {
      padding: 5px;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .ruleGroup-addGroup {
      display: none;
    }

    .ruleGroup-header {
      &:before {
        content: 'Match Rules ' !important;
        color: #3B3B3B !important;
      }

      button {
        margin-left: 10px;
      }

      select {
        margin-top: 10px;
        padding: 5.5px;
        /*   width: 94%; */
      }
    }

    select.ruleGroup-combinators {
      margin-left: 10px;
      width: 100px;
    }

    select.rule-fields,
    select.rule-operators {
      width: 245px;
      padding: 5px;
      border-radius: 4px;
      // margin-top: 20px;
    }

    .remove_rule_action {
      position: absolute;
      top: -7.5px !important;
      right: -7.5px;
      background-color: #4f4f4f;
      color: #fff;
      padding: 0px;
      border-radius: 50%;
      width: 15px;
      text-align: center !important;
      height: 15px;

      .fas::before {
        position: relative;
        top: -3px;
        font-size: 9px;
      }
    }

    input {
      border: 1px solid #DBDBDB;
      border-radius: 5px !important;
      padding: 4px !important;
      width: 245px;
    }

  }

  .query_builder {
    // background-color: #fafafa !important;
    /*   box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02); */
    // border: 1px solid rgba(226, 226, 226, 0.4);
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 5px;
    margin-top: 5px;

  }
}