.sticky {
  z-index: 1;
}
.action-bar-container {
  // position: relative;
  // z-index: 9999999999999 !important;
  background-color: #eee;
  padding: 3px 8px;
  width: 100%;
  border-bottom: 1px solid #dadada;

  display: flex;
  align-items: center;
  justify-content: center;

  .border-vertical {
    position: relative;
    top: 5px;
    border-left: 1px solid #dadada;
    height: 24px;
    margin-left: 10px;
  }
  .action-menu {
    display: flex;
    flex-direction: row;
    width: 200px;
    position: relative;
    left: -30px;

    .action-menu-item {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 0 2px;
      .sub-item {
        padding: 2px;
        font-size: 12px;
      }
      .fa-hand-paper {
        &::before {
          font-size: 14px;
        }
      }
      .fa-mouse-pointer {
        &::before {
          font-size: 14px;
        }
        margin-left: 15px;
      }

      .fa-arrow-alt-circle-down,
      .fa-plus {
        position: relative;
        left: -5px;
      }

      .icon-highlight {
        color: #2692d9;
      }

      .icon {
        font-size: 12px;
      }
    }
  }
}
