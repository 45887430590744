.signature_field {
  margin-top: 40px;
  margin-bottom: 40px;
  .tabs {
    li {
      position: relative;
      left: -14px;
      a {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .form_sigCanvas {
    border: 1px solid #dadada;
  }

  .signature_clear {
    color: #fff !important;
    width: 50px !important;
    position: relative;
    top: 50px;
    left: 10px;
    background-color: #fff !important;
    margin-top: -50px;
    i {
      color: #182838 !important;
    }
  }

  .google_font_select {
    #font-picker {
      z-index: 99;
      max-width: 183px !important;

      button {
        background-color: #fff !important;
      }
    }
  }
  .type-sign {
    min-width: 100%;
    min-height: 170px;
    border: 1px solid #dadada;
    padding: 10px;
    position: relative;
    top: -8px;
    input {
      font-size: 52px;
      border: 0px;
      text-align: center;
      box-shadow: none;
    }
    div[id^='font-picker'] {
      // max-width: 150px !important;
      box-shadow: none;
    }
    .dropdown-button {
      background-color: #fff !important;
      border: 1px solid #dadada;
      box-shadow: none;
      border-radius: 3px;
    }
  }

  .image-uploader-signature {
    margin-bottom: 10px;
    .signature_upload_clear {
      color: #fff !important;
      width: 50px !important;
      position: absolute;
      top: 60px;
      left: 10px;
      background-color: #fff !important;
      margin-top: -50px;
      i {
        color: #182838 !important;
      }
    }
    .image-uploader-drag {
      min-width: 100%;

      background-color: #fff;

      .active {
        border-radius: 6px;
        border: 1px dashed #dadada;
      }

      .not-active {
        border-radius: 6px;
        border: 2px dashed #ccc;
      }

      .uploader {
        display: flex;
        justify-content: center;
        min-width: 100%;
        min-height: 180px;
        align-items: center;
      }
    }

    .image-uploader-file {
      min-width: 100%;
      padding: 10px;
      border: 2px dashed #ccc;
      background-color: #fff;
      border-radius: 6px;
      min-height: 180px;
      display: flex;
      justify-content: center;
      align-items: center;

      .signature-preview {
        display: block;
        width: auto;
        max-height: 180px;
      }
    }

    p {
      font-size: 14px;
      position: relative;
      z-index: 99;
    }

    .fa-times-circle {
      &:hover {
        cursor: pointer;
        color: red;
      }
    }
  }
}
/*--------[Normal Phones]--------*/
@media only screen and (max-width: 540px) {
  .signerEmailInput {
    margin-top: -5px;
  }
  .signature_consent {
    position: relative;
    top: 20px;
  }
}
