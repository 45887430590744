@import '../../../constants/css/styles';

.fields_container {
  border-left: 1px solid $gray2;
  border-right: 1px solid $gray2;
  // border-right: 1px solid $gray2;
  min-width: 300px;
  max-width: 300px;
  // position: relative;
  // right: 0px;
  margin-right: 60px !important;
}

.label {
  font-weight: 400 !important;
}
