@import '../../../constants/css/styles';

.document_preview {
  background-color: #fafafa;
  height: $document_editor_height;
  overflow-y: scroll;
  border-left: 1px solid $gray2;
  text-align: center;
  display: flex;
  flex-direction: column;

  .container {
    // text-align: center;
    display: flex;
    justify-content: center;
    margin-top: 50px;
    width: 100% !important;
    height: auto !important;
    canvas {
      margin-top: 20px;
      margin-left: -40px;
      box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
        0 0px 0 1px rgba(10, 10, 10, 0.02);
    }
  }

  .document_loader_container {
    position: relative;
    left: 0%;
    top: 30%;
  }

  .rendered_toolbar {
    min-width: calc((100vw / 2) - 60px);
    max-width: 50%;
    max-height: 60px !important;
    background-color: #fff;
    border-bottom: 1px solid #dadada;
    position: fixed;
  }
  .generate_button {
    display: flex;
    .download_progress {
      max-height: 25px;
      margin-right: 10px;
    }
  }
}
