.apps {
  .loader_container {
    text-align: center;
    position: relative;
    top: 100px;
  }

  .media-left {
    margin-right: 0px;
  }
  .content {
    .has-text-grey {
      position: relative;
      top: -12px;
    }
  }
  .integration-logo {
    max-height: 50px;
    width: auto;
  }
}
.react-responsive-modal-modal {
  top: 15vh;
  border-radius: 6px;
  width: 500px;
  .title {
    font-size: 16px !important;
  }
  p {
    font-size: 14px;
    margin-bottom: 10px;
  }
  label {
    font-size: 14px;
    font-weight: 700 !important;
  }
  .react-responsive-modal-closeButton svg {
    max-height: 24px;
  }
  .new-integration-block {
    padding-left: 10px;
    padding-top: 10px;
    cursor: pointer;
    border: 1px solid #dadada;
    margin-bottom: 10px;
    img {
      max-height: 50px;
      width: auto;
    }
    .app-description {
      margin-top: -10px;
    }
  }
}

.app_settings_page {
  .loader_container {
    text-align: center;
    position: relative;
    top: 100px;
  }
  p {
    margin-bottom: 20px;
    font-size: 14px;
  }
  .button {
    font-size: 12px !important;
  }
  table {
    font-size: 14px;
    .dns_value {
      display: flex;
      background-color: #f5f5f5;
      max-width: 300px;
      pre {
        max-width: 260px;
        font-size: 12px;
        white-space: break-spaces;
        overflow-wrap: anywhere;
      }
      .copy_button {
        z-index: 999;
        margin: 4px;
      }
    }
  }
}
