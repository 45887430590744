.image-uploader {
  .image-uploader-drag {
    min-width: 100%;

    background-color: #fff;

    .active {
      border-radius: 6px;
      border: 2px dashed rgba(39, 147, 218, 0.2);
    }

    .not-active {
      border-radius: 6px;
      border: 2px dashed #ccc;
    }

    .uploader {
      display: flex;
      justify-content: center;
      min-width: 100%;
      min-height: 180px;
      align-items: center;
    }
  }

  .image-uploader-file {
    min-width: 100%;
    padding: 10px;
    border: 2px dashed #ccc;
    background-color: #fff;
    border-radius: 6px;
    min-height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;

    .image_upload_clear {
      color: #fff !important;
      width: 50px !important;
      position: absolute;
      top: 60px;
      left: 10px;
      background-color: #fff !important;
      margin-top: -50px;
      i {
        color: #182838 !important;
      }
    }
  }
  .image-preview {
    display: block;
    width: auto;
    max-height: 180px;
  }

  p {
    font-size: 14px;
    position: relative;
    z-index: 99;
  }

  .fa-times-circle {
    &:hover {
      cursor: pointer;
      color: red;
    }
  }
}

/*--------[Nromal Phones]--------*/
@media only screen and (max-width: 540px) {
  .image-uploader-drag p {
    padding: 10px;
  }
}
