@import 'constants/css/styles.scss';

.activity_log_page {
  background-color: $page_background;
  min-height: $page_min_height;

  .loader_container {
    text-align: center;
  }
  .activity_log_section {
    margin-top: -30px;
    .log_table {
      margin-top: 0px;
      .rdt_TableHeader {
        display: none;
      }
      .rdt_TableCol {
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
  .box {
    min-height: 400px;
    .loader_container {
      height: 400px;
    }
  }

  .object_type {
    .object_link {
      cursor: pointer;
      border-radius: 3px;
      padding: 5px;
      color: #000 !important;
      box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
        rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
      i {
        margin-right: 4px;
      }
      .fa-file-pdf {
        color: #a50000;
      }
      .fa-external-link-alt {
        font-size: 10px;
        margin-left: px;
        position: relative;
        top: -1px;
        left: 5px;
        color: rgba(0, 0, 0, 0.3);
      }
    }
    .disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
