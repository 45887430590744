@import 'constants/css/styles.scss';
@import 'bulma-switch/dist/css/bulma-switch.min';

.workflowEditor_toolbar {
  border-bottom: 1px solid $gray2;
  position: relative;
  z-index: 4 !important;
  background-color: $editor_background !important;

  .workflow_title {
    color: #000 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    font-size: 14px;

    .edit_workflow_title {
      display: none;
    }
  }

  .workflow_title:hover {
    cursor: pointer;
  }
}
