@import 'constants/css/styles';

.thumbnail {
  padding: 0px;
  width: auto !important;
  max-width: 180px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: $gray1;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0;
    top: 30px !important;
    width: 100%;
    height: 100px;
  }
}

.thumbnail.selected {
  background-color: $gray2;
  max-width: 180px !important;

}

.thumbnail .page {
  // Donot put flex properties here!
  border: 1px solid $gray1;

}

.thumbnail .page_number {
  padding-top: 10px;
  font-size: 12px;
}

.thumbnail .page_number_ghost {
  color: transparent; //hacky
  padding-top: 10px;
}