.field_select {
  .dropdown-content {
    // border: 1px solid rgb(230, 230, 230);
    border: 1px solid #1951a3;
    .tabs ul {
      font-size: 12px !important;
    }
  }
  .field_button {
    button {
      max-width: 38px !important;
    }
  }
}
