@import '../../../constants/css/styles';
@import 'bulma-checkradio';

.document_form {
  background-color: #fff;
  height: $document_editor_height;
  overflow-y: scroll;

  form {
    padding: 20px;
    font-size: 14px;

    .label,
    .control label,
    input,
    textarea {
      font-size: 13px;
      // font-weight: 600 !important;
    }
    label.label {
      font-weight: 600 !important;
    }
    .group_field {
      margin-top: 30px;
      .description {
        margin-bottom: 10px;
      }
      .label_hr {
        margin-top: 10px;
      }
      .fields_hr {
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .fields {
        margin-left: 10px;
      }
    }
  }
}
