.input_editor {
  margin-top: 5px;

  .control:nth-child(2) {
    height: 0px;
    margin-bottom: -5px;
  }

  .input_editor_slate {
    background-color: #fff;
    border: 1px solid #dadada;
    padding: 5px;
    border-radius: 3px;

    .highlight {
      background-color: #E9FBE7;
      color: #5F985F;
    }
  }

  .input_editor_dropdown {
    .dropdown-content {
      position: relative;
      top: 0px;
      min-width: 245px;
      position: relative;
      left: 245px;
      max-height: 400px;
      overflow-y: scroll;

      small {
        font-weight: 700;
      }

      .is-active {
        background-color: #ddd;
        color: #5F5F5F;
      }

      .msg {
        font-size: 10px !important;
        font-weight: 500;
      }

      .close {
        position: relative;
        left: 160px;
        // font-weight: 600;
        cursor: pointer;
      }
    }
  }
}