@import 'bulma/sass/utilities/_all.sass';
@import 'bulma/sass/grid/columns.sass';
@import 'bulma-extensions/bulma-tooltip/dist/css/bulma-tooltip.sass';
@import './variables.scss';
@import './common.scss';
@import './responsive.scss';
@import './utils.scss';

/* Source: https://github.com/jgthms/bulma/issues/847 */
@import '~bulma/sass/utilities/mixins';
.element {
  &.is-loading {
    position: relative;
    pointer-events: none;
    opacity: 0.5;
    &:after {
      @include loader;
      position: absolute;
      top: calc(50% - 2.5em);
      left: calc(50% - 2.5em);
      width: 5em;
      height: 5em;
      border-width: 0.25em;
    }
  }
}

@import '~@creativebulma/bulma-collapsible';