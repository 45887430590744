.dropdown-with-button{
  display: flex;
  flex-direction: row;
  .main-button{
    width: 100px;
    
  }
  .dropdown-trigger{
    .button{
      position: relative;
      left: -2px;
    }
  }
  .dropdown-menu{
    .dropdown-content{
      width: 128px;
      position: relative;
      left: 62px;
      
    }
    a{
      padding: 7px;
      font-size: 12px;
      // text-transform: uppercase;
      font-weight: 500;
    }
    hr{
      margin-top: 2px;
      margin-bottom: 2px;
    }
  }

}