.margin-right-5 {
  margin-right: 5px;
}
.margin-top-5 {
  margin-top: 5px;
}
.margin-left-10 {
  margin-left: 10px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.font-14px {
  font-size: 14px !important;
}
.remove-scrollbars {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
  overflow: -moz-scrollbars-none !important;

  &::-webkit-scrollbar {
    /* WebKit */
    display: none !important;
    width: 0 !important;
    height: 0 !important;
  }
}
