@import 'constants/css/variables.scss';

.loadingbar {
  position: absolute !important;
  top: 0;
  z-index: 9999999999 !important;
  background-color: #fff !important;
  height: 2px !important;
}


.topbar {
  // background-color: $primary !important; //primary700
  background-image: linear-gradient(to right, #051d39, #1e344d);
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
  position: sticky;
  top: 0px;

  .navbar-brand {
    svg {
      fill: #fff !important;
      height: 20px;
    }

    span {
      position: relative;
      left: 10px;
    }
  }

  .container .navbar-start {
    margin-left: 25px;
  }

  .dropdown-content {
    position: relative;
    z-index: 9999 !important;
  }

  .navbar-item,
  .navbar-item>span a {
    color: #EBF3FF; //primary100
    font-size: 14px;
    font-weight: 500;
    outline: none;
  }

  .navbar-item span i {
    margin-right: 5px;
  }

  .active_page {
    position: relative;
    top: 2px;
    border-bottom: 3px solid #fff;
  }

  .navbar-item {

    &>a,
    .dropdown-trigger>a {
      color: #EBF3FF; //primary100
      font-size: 14px;
      font-weight: 500;
    }

    .dropdown-trigger i {
      margin-left: 10px;
    }
  }
}