@import 'constants/css/styles.scss';

.form_library {
  margin-right: 10px;
}

.user_documents {
  background-color: $page_background;
  min-height: $page_min_height;

  // &>section {
  //   position: relative;
  //   top: 20px;
  // }

  .documents_loader_container {
    text-align: center;
    margin-top: 100px;
  }

  .card {
    margin-bottom: -70px;
    margin-right: 10px;
  }
  .dzu-dropzone {
    label {
      font-size: 14px;
    }
  }
  h3 {
    font-size: 20px !important;
    color: #1951a3;
  }

  .document_section {
    // margin-top: -100px;
    font-size: 14px !important;
    .empty {
      margin-top: -30px;
    }
    .filters {
      margin-top: 15px;
      max-width: 300px;
      margin-bottom: 20px;
      display: flex;

      .select {
        flex: 0;
      }

      .control {
        min-width: 200px;
        font-size: 14px !important;
        flex: 0;
        margin-left: 10px;

        input {
          height: 35px;
        }
      }
    }
  }

  .documents-container {
    display: flex;
    flex-wrap: wrap;
    // justify-content: center;

    margin-top: 20px;
    margin-left: 30px;
    margin-right: 20px;
  }

  .document-item {
    justify-content: left;
    padding: 0 10px 90px 10px;
    margin-left: -10px;
  }
}
