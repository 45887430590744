.keyvaluepair {
  margin-left: -20px;

  &>.field>.control {
    margin-left: 20px;
  }

  input::placeholder {
    color: #4A4A4A;
  }
}