.file-explorer {
  margin-top: 20px;
  font-size: 14px !important;
  margin-bottom: 20px;
  tbody tr {
    cursor: pointer;
  }
  i {
    font-size: 18px !important;
    margin-right: 10px;

    padding: 8px;
    border-radius: 3px;
    text-align: center;
    min-width: 32px;
  }

  .filters {
    margin-left: -30px;
    position: relative;
    top: -30px;

    .select {
      margin-left: 10px;
      margin-bottom: 10px;
    }
  }
  .rdt_Table {
    min-height: 70vh !important;
  }
  .rdt_TableHeader {
    & > div {
      font-size: 14px !important;
    }
    min-height: 40px;
  }
  .rdt_TableCol {
    font-size: 14px;
    font-weight: 700;
  }
  .rdt_TableHeadRow {
    font-size: 14px !important;
  }
  .rdt_TableRow {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .rdt_TableCell {
    font-size: 14px !important;
    a {
      color: #272b34;
    }
  }
  .rdt_Pagination {
    position: relative;
    z-index: 1 !important;
  }

  .pagination {
    margin-top: 10px;
    font-size: 14px !important;
  }
  .button-folder {
    border-radius: 3px;
    i {
      margin-left: 10px;
      font-size: 14px !important;
    }
  }

  .file-item {
    max-width: 500px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    .fa-file-pdf {
      color: #a50000;
      background-color: #faeeef;
    }
    .fa-folder {
      color: #2793da;
      background-color: #f0f6fc;
    }
  }
  .level-left,
  .level-item {
    flex-shrink: 1 !important;
  }
  .folder-breadcrumb {
    margin-left: 6px;

    a {
      color: #272b34;
      font-weight: 500;
    }
    .fa-folder {
      color: #2793da;
      background-color: #f0f6fc;
    }
    .fa-angle-right {
      margin-left: 10px;
    }
  }
  .dropdown {
    position: relative;
    left: 80%;
    button {
      max-width: 40px;
    }

    .dropdown-item {
      margin-bottom: -10px;
      font-size: 12px;
      i {
        position: relative;
        font-size: 12px !important;
        left: 5px;
        margin-right: 0px;
      }
    }
    .dropdown-menu {
      z-index: 999 !important;
    }
    i {
      position: relative;
      left: 5px;
    }
  }
}

.move_modal {
  .loader_container {
    margin-top: 25px;
    text-align: center;
  }
}

.tree {
  max-height: 350px;
  overflow: auto;
  overflow-y: auto !important;
  .tree-item {
    display: flex;
    min-width: 400px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: -3px;
    padding-right: 10px;
    padding-left: 10px;
    border-top: 1px solid #ebebeb;
    // border-bottom: 1px solid #ebebeb;
  }
  .label {
    font-size: 14px;
    i {
      font-size: 16px;
      color: #2793da;
    }
  }
  .tree-check {
    margin-right: 10px;
  }
  .tree-children {
    padding-left: 10px;
  }
}
