@import 'constants/css/styles';

.fields_panel {
  margin-bottom: 10px !important;
  height: calc(32px + #{$sidebar_height});
  overflow-y: scroll;

  margin-top: 0px !important;

  .innerFields {
    margin-left: 22px;
    border-radius: 0px;
    > a {
      border-top: 1px solid #dadada;
    }
  }

  .even_icon {
    background-color: #eaeaea !important;
  }

  .odd_icon {
    background-color: #f5f5f5 !important;
  }

  .field_icons {
    position: absolute;
    right: 0;
    margin-right: 10px;

    padding: 3px;
    background-color: #dadadabd;
    border-radius: 5px;

    i {
      position: relative;
      right: 2;
      margin-left: 7px;
    }

    .field_icon_duplicate {
      color: #444444;
      opacity: 0.9;

      &:hover {
        color: #3273dc;
      }
    }

    .field_icon_delete {
      color: #444444;
      opacity: 0.9;
      margin-right: 5px;
      &:hover {
        color: red;
      }
    }
  }

  .is-active {
    font-weight: 700;
    color: #3273dc !important;
    // border-top: 1px solid #3273DC !important;
    // border-bottom: 1px solid #3273DC !important;
  }

  .page_header {
    cursor: pointer;

    .Collapsible {
      padding-top: 10px !important;
      background-color: #fdfdfd;
      border-bottom: 1px solid #dadada;

      .Collapsible__trigger {
        font-size: 12px;

        span i {
          padding-bottom: 10px !important;
        }

        .page_number {
          margin-left: 10px;
        }
      }

      & > span {
        margin-left: 20px !important;

        i {
          font-size: 18px !important;
          color: #dadada;
        }
      }
    }

    a {
      font-size: 12px !important;
      border-radius: 0px;
      border-radius: 5px;
      margin-left: 10px;
      color: #444444;

      &:hover {
        color: #3273dc !important;
      }
    }

    ul {
      background-color: #f5f5f5 !important;

      nav {
        margin-top: 5px;
        margin-bottom: 5px;
      }

      .even {
        background-color: #eaeaea !important;

        a:hover {
          background-color: #eaeaea !important;
        }

        .is-active {
          background-color: #eaeaea !important;
        }
      }

      .odd {
        .is-active {
          background-color: #f5f5f5 !important;
        }
      }
    }
  }
}
