@import '../../../constants/css/styles';

.document_container {
  min-height: $document_editor_height !important;
  overflow-y: scroll;
  // height: 100%;
}

.document_container {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
  overflow: -moz-scrollbars-none !important;
  position: relative;

  &::-webkit-scrollbar {
    /* WebKit */
    display: none !important;
    width: 0 !important;
    height: 0 !important;
  }
}

.document_container img.page {
  border: 1px solid #dadada;
  box-shadow: 0 2px 1px -1px gray;
  cursor: default !important;
}

.pagenumber {
  // position: relative;
  // right: 50px;
  // top: -35px;
  // padding: 10px;
  // color: $gray3;
  // background-color: #fafafa;
  // border: 1px solid $gray2;
  // border-radius: 10px 10px 10px 10px;
  display: none;
}
.document_footer {
  font-size: 14px;
  border-top: 1px solid #dadada;
  background-color: #fefefe !important;
  height: 30px;
  padding: 20px 10px 20px !important;
  width: 100% !important;
  // height: 40px !important;
}
