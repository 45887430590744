@import 'constants/css/styles.scss';

.login {
  background-color: $page_background;
  height: 100vh;
  overflow-y: scroll !important;

  .partner-logo {
    max-width: 140px;
    margin-left: 15px;
    border-left: 1.5px solid black;
  }

  .brand {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 60px;

    svg {
      position: relative;
      fill: $primary;
      left: -10px;
    }

    h4 {
      font-weight: 700;
      font-size: 24px !important;
    }
  }

  .section {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    position: relative;
    // top: 20vh;
  }

  .box {
    width: 450px;

    form,
    .form {
      padding: 10px;
      margin: 10px;
      margin-bottom: 30px;
      font-size: 14px;

      .label,
      .input,
      .button {
        font-size: 14px;
      }

      .field {
        margin-top: 30px;
      }

      .message-body {
        font-size: 14px !important;
      }

      h4 {
        font-size: 18px !important;
        text-align: center;
      }
    }

    .loader_container {
      height: 150px;
      text-align: center;
      padding: 20px;
      -webkit-transition: -webkit-transform 1s linear;
      transition: 1s linear;
      -moz-transition: 1s linear;

      span {
        position: relative;
        top: 20px;
      }
    }

    .login-links {
      font-size: 14px;
      position: absolute;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, 50%);

      a {
        color: #1951a3 !important;
      }

      a:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}
