@import 'constants/css/styles.scss';
@import 'bulma-switch/dist/css/bulma-switch.min';

.workflow_box {
  &:hover {
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.2),
      0 0px 0 1px rgba(25, 81, 163, 0.02) !important;
    //   border: 1px solid $primary;
    // border: 1px solid #d6ddea;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
    -webkit-box-shadow: 0 8px 10px -4px rgba(93, 96, 99, 0.08),
      0 1px 2px 0 rgba(71, 80, 89, 0.03);
    box-shadow: 0 8px 10px -4px rgba(71, 80, 89, 0.08),
      0 1px 2px 0 rgba(71, 80, 89, 0.03);
  }

  .fa-caret-right {
    display: none;
  }

  .dropdown {
    button {
      max-width: 40px;
    }

    .dropdown-item {
      font-size: 12px;
    }

    i {
      position: relative;
      left: 5px;
    }
  }

  font-size: 14px;

  img {
    background-color: #f5f5f5;
    padding: 10px;
    height: 45px;
    width: 45px;
    border-radius: 4px;
    border: 1px solid #dadada;
    margin-right: 10px;
  }

  .step_icon {
    background-color: #f5f5f5;
    padding: 10px;
    height: 45px;
    width: 45px;
    border-radius: 4px;
    border: 1px solid #dadada;
    margin-right: 10px;
    font-size: 22px;
    color: $primary;
  }

  .workflow_name {
    position: absolute;
    width: 300px;
    height: 85px;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, 0%);
    cursor: pointer;
  }

  .icons {
    cursor: pointer;
  }

  .button {
    max-width: 40px;

    i {
      position: relative;
      left: 5px;
    }
  }

  .switch_label {
    position: relative;
    top: -10px;
  }

  .card_edit {
    font-size: 12px;
  }

  .card_delete {
    font-size: 12px;
  }

  i {
    margin-right: 10px;
  }

  .card_edit {
    color: #1951a3;
    /*primary700*/
  }

  .card_delete {
    color: #1951a3;
    /*primary700*/
  }
}
