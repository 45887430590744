@import '../../../constants/css/styles';
@import 'bulma/sass/components/navbar.sass';

.toolbar {
  border-bottom: 1px solid $gray2;
  margin-top: 52px !important;
  position: relative;
  z-index: 10 !important;

  .document_title {
    color: #000 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    background-color: #f5f5f5;
    padding: 2px;
    border-radius: 3px;

    p {
      font-size: 14px !important;
    }
  }
}
