@import '../../../constants/css/styles';

.left_sidebar {
  border-right: 1px solid $gray2;
  min-width: 200px !important;
  min-width: 220px !important;
  // max-width: 300px;
  border-left: 1px solid $gray2;
  display: flex;

  .tabs {
    margin-bottom: 0px;
    background-color: #eee;

    ul a {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
