.signaturePrompt {
  .modal-card {
    position: relative;
    top: 0% !important;
    min-width: 600px !important;
    label {
      display: none;
    }
    .tabs {
      background-color: #fff;
      ul {
        position: relative;
        left: -10px;
      }
      margin-top: -30px;
    }
    img {
      display: block;
      margin: 0 auto;
    }
    .is-horizontal {
      padding: 0px !important;
      .field-body {
        padding: 0px;
        .field {
          input {
            min-width: 100%;
          }
        }
      }
    }
    .type-sign {
      input {
        font-size: 52px !important;
        min-width: 100%;
        &::-webkit-input-placeholder {
          font-size: 52px;
        }
      }
    }
    canvas {
      margin-top: 10px;
    }
  }
  .signature_field {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
}
