@import '../../constants/css/styles.scss';

.workflow_editor_container {
  margin-top: 52px !important;

  p.help-long {
    margin-top: 5px;
  }

  .loader_container {
    position: absolute;
    top: 40vh;
    left: 50%;

    span {
      position: relative;
      font-size: 14px;
      top: 10% !important;
      left: -40% !important;
    }
  }

  .workflow_editor {
    overflow-x: hidden;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    width: calc(100% - 60px);

    .drawer {
      z-index: 5;
    }

    .picker-button {
      width: 175px;
      background-color: rgb(255, 255, 255);
      display: inline-flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.54);
      box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
        rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
      padding: 0px;
      border-radius: 2px;
      border: 1px solid transparent;
      font-size: 14px !important;
      font-weight: 500;
      font-family: Roboto, sans-serif;
    }
  }

  .workflow_steps_column {
    overflow-y: scroll;
    max-height: $document_editor_height !important;
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;
    overflow: -moz-scrollbars-none !important;
    background-color: #f5f5f5 !important;
    border-right: 1px solid #d3d3d3;
  }

  .workflow_steps {
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;
    overflow: -moz-scrollbars-none !important;

    &::-webkit-scrollbar {
      /* WebKit */
      display: none !important;
      width: 0 !important;
      height: 0 !important;
    }
  }

  .workflow_steps {
    background-color: #f5f5f5;
    padding: 20px;
    font-size: 14px !important;

    img.step_logo {
      background-color: #f5f5f5;
      padding: 10px;
      height: 45px;
      width: 45px;
      border-radius: 4px;
      border: 1px solid #dadada;
      margin-right: 10px;
    }

    .fa-trash-alt {
      margin-right: 5px;
    }

    .step_icon {
      background-color: #f5f5f5;
      padding: 10px;
      height: 45px;
      width: 45px;
      border-radius: 4px;
      border: 1px solid #dadada;
      margin-right: 10px;
      font-size: 22px !important;
      color: $primary;
    }

    .box {
      position: relative;
      z-index: 100 !important;
      margin-bottom: 30px;
      border: 1px solid #fff;

      &:nth-last-child(4) {
        margin-bottom: 20px !important;
      }

      &:nth-last-child(3) {
        margin-bottom: 20px !important;
      }
    }

    .dropdown-item {
      font-size: 12px !important;
    }

    .box.active {
      border: 1px solid #1951a3;
    }

    .new_button {
      border-radius: 1000px !important;
      padding: 25px !important;
      margin-top: 27px !important;
      position: absolute;
      z-index: 10;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
    }

    .button {
      max-width: 40px;
    }

    .hline {
      position: absolute;
      width: 2px;
      z-index: 0;
      background-color: #949ca0;
      height: 100px;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
    }

    .step_arrow {
      color: rgb(150, 158, 162);
      margin-top: -5px;
      position: absolute;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      z-index: 4;
    }

    .step_arrow:nth-last-child(2) {
      display: none;
    }
  }

  .step_setting {
    height: $document_editor_height;
    background-color: #fff;
    font-size: 14px !important;
    overflow-y: scroll;
    overflow-x: hidden;

    .dropdown-menu {
      position: absolute;
      z-index: 101 !important;
    }

    .message-body {
      font-size: 12px;
    }

    img {
      background-color: #f5f5f5;
      padding: 10px;
      height: 50px;
      width: 50px;
      border-radius: 4px;
      border: 1px solid #dadada;
      margin-right: 10px;
    }

    .step_icon {
      background-color: #f5f5f5;
      padding: 10px;
      height: 50px;
      width: 50px;
      border-radius: 4px;
      border: 1px solid #dadada;
      margin-right: 10px;
      font-size: 22px !important;
      color: $primary;
    }

    form {
      margin-bottom: 20px;

      .fields {
        padding: 24px;

        .tabs {
          font-size: 14px;
          i {
          }
        }
      }

      p.pull-up {
        position: relative;
        top: -8px;
        margin-bottom: 8px;
      }
    }

    .step_bar {
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 3;
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #dadada;

      label {
        font-weight: 600;
        cursor: pointer;
      }
    }

    .columns .column {
      max-width: 280px;
    }

    .email_setting {
      div[class$='-control'] {
        height: 45px;
      }
    }

    .label,
    .control,
    .control input,
    .control textarea {
      font-size: 14px !important;
    }

    .control > input {
      min-height: 40px !important;
    }

    .label {
      font-weight: 600 !important;
    }

    .document_card {
      min-width: 200px !important;
    }

    .link_button {
      max-width: 38px !important;

      i {
        font-size: 14px;
      }
    }
    .copy_button {
      max-width: 38px !important;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      // border-right: 0px;
      i {
        font-size: 14px;
      }
    }
  }
}

.step-editable-name i {
  color: #ccc;
}

.step-editable-name:hover i {
  color: #363636;
}

.new_step {
  .step_bar {
    .step_name {
      position: relative;
      top: 5px;
      font-size: 14px;
      font-weight: 600;
    }

    height: 50px;
  }

  .full-width-card {
    cursor: pointer;

    a {
      color: #363636;
      outline: 0;

      &::-moz-focus-inner {
        border: 0;
        border-color: transparent;
      }
    }

    .card {
      padding-top: 30px !important;
      padding: 20px;
      margin-left: 10px;
      min-width: 98% !important;
      height: 75px;
    }
  }

  .card:not(.long-card) {
    margin-top: 20px;
    margin-left: 20px;
    height: 300px;
    width: 50em !important;
    .card_step_name {
      font-weight: 600;
    }

    cursor: pointer;

    i {
      background-color: #f5f5f5;
      padding: 10px;
      height: 50px;
      width: 50px;
      border-radius: 4px;
      border: 1px solid #dadada;
      margin-right: 10px;
      font-size: 22px !important;
      color: $primary;
    }

    .content {
      justify-content: center;
    }
  }
}

.not-last-step a {
  pointer-events: none;
  filter: grayscale(1);
  opacity: 0.5;
}

// This is for the drawer component
.workflow_settings {
  label {
    span {
      font-weight: 400 !important;
    }
    font-weight: 700 !important;
  }
  label,
  input,
  select,
  ul,
  li button {
    font-size: 14px !important;
  }
  .help {
    position: relative;
    top: -10px;
    margin-bottom: 10px;
  }
  .reselect {
    * {
      font-size: 14px !important;
    }
  }
}
.workflowsetting_loader {
  text-align: center;
  margin-top: 30px;
}
.step_row {
  .fa-caret-right {
    display: none;
  }
  img.step_logo {
    background-color: #f5f5f5;
    padding: 10px;
    height: 45px;
    width: 45px;
    border-radius: 4px;
    border: 1px solid #dadada;
    margin-right: 10px;
  }
  .step_row_name {
    color: #000;
    margin-left: -10px;
    font-size: 14px !important;
  }
}
