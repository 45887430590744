@import 'constants/css/styles.scss';

.workflows {
  background-color: $page_background;
  min-height: $page_min_height;

  .fullwidth_loader_container {
    text-align: center;
    margin-top: 100px;
  }

  .workflow_table {
    margin-top: 20px;
    font-size: 14px !important;

    .rdt_Table {
      min-height: 70vh !important;
    }
    .rdt_TableHeader {
      display: none;
    }
    .rdt_TableCol {
      font-size: 14px;
      font-weight: 700;
    }
    .rdt_TableHeadRow {
      font-size: 14px !important;
    }
    .rdt_TableRow {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .step_row {
      max-width: 400px;
      .step_row_name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .step_icon {
      background-color: #f5f5f5;
      padding: 10px;
      height: 45px;
      width: 45px;
      border-radius: 4px;
      border: 1px solid #dadada;
      margin-right: 10px;
      font-size: 22px;
      color: $primary;
    }
    .dropdown {
      .dropdown-item {
        font-size: 12px;
      }
    }

    .filters {
      margin-top: 15px;
      max-width: 300px;
      margin-bottom: 20px;
      display: flex;

      .select {
        flex: 0;
      }

      .control {
        min-width: 200px;
        font-size: 14px !important;
        flex: 0;
        margin-left: 10px;

        input {
          height: 35px;
        }
      }
    }
    .empty {
      margin-top: 30px;
    }

    .workflow_box {
      margin-top: 10px;
      margin-left: 10px;
      min-width: 980px;
    }
  }

  .workflows-container {
    margin-top: 20px;
  }

  .workflow-item {
    padding: 5px;
    margin-left: -10px;
  }

  button.upload {
    // background-color: #1951A3;
    color: #fff;
    // border-radius: 6px;
    // border: 2px solid #1951A3;
    font-size: 14px;
    font-weight: 500;
  }

  h3 {
    // font-size: 20px !important;
    color: #1951a3;
  }

  .document_section {
    margin-top: -20px;
  }

  button.upload {
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
      rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
  }

  span {
    font-size: 14px;
  }
  .link_buttons_table {
    a {
      padding: 12px;
      max-height: 10px !important;
      padding-top: 15px;
      padding-bottom: 14px;
    }
    .copy_button {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      border-right: 0px;
      i {
        font-size: 14px !important;
      }
    }
    .link_button {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      i {
        font-size: 12px !important;
      }
    }
  }
}
