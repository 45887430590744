// viewport - navbar - toolbar
$document_editor_height: calc(100vh - 52px - 53px);

// viewport - navbar - toolbar - tabs - padding after the tabs
$sidebar_height: calc(100vh - 52px - 53px - 39px - 23px);

// navbar height
$page_navbar_height: 65px;
$page_sidebar_width: 210px;
$page_min_height: calc(100vh - 2 * 65px);

// Colors
$page_background: #f3f5f8; //cool upflow white:
$editor_background: #f3f5f8;

$gray1: #eaeaea;
$gray2: #d3d3d3;
$gray3: #999;

$primary: #182838; //#1951A3;

$navbar_color: #1951a3; //mixpanel blue: #004FE2

$background: #f3f5f8;
// $background: #F3F5F8;
