@import 'constants/css/styles';
@import 'bulma-divider/src/sass/index.sass';
@import 'bulma-slider/dist/css/bulma-slider.min';

.fields_container {
  scrollbar-width: none !important;
  /* Firefox */
  -ms-overflow-style: none !important;

  &::-webkit-scrollbar {
    display: none !important;
    /* Safari and Chrome */
  }

  .tabs {
    // margin-bottom: 0px;
    background-color: #eee;

    ul a {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  select:not(.ruleGroup-combinators) {
    min-width: 183px;
    left: 0px;
  }

  .field_editor {
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;
    overflow: -moz-scrollbars-none !important;

    &::-webkit-scrollbar {
      /* WebKit */
      display: none !important;
      width: 0 !important;
      height: 0 !important;
    }
  }

  .text_field_editor {
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;
    overflow: -moz-scrollbars-none !important;
    // font-size: 14px !important;
    margin-top: 25px;
  }

  .text_field_editor::-webkit-scrollbar {
    /* WebKit */
    display: none !important;
    width: 0 !important;
    height: 0 !important;
  }

  .field_editor {
    height: calc(32px + #{$sidebar_height});
    position: relative;
    top: -23px;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;

    .field-label {
      flex-grow: 1; //SOURCE: https://github.com/jgthms/bulma/issues/1852
      /* overwrites previous value of 1 */
    }

    .is-horizontal {
      margin-top: 10px;
      padding-left: 15px;
      padding-right: 15px;

      label {
        width: 45px;
        text-align: left;
      }

      .select {
        min-width: 175px !important;
      }
      .is-select {
        margin-right: 10px;
        &:after {
          display: none;
        }
        i {
          margin-left: 8px;
          margin-right: 8px;
        }
      }
    }

    .color .dropdown-item {
      position: relative;
      left: -60px;
    }

    .is-divider {
      background-color: $gray1 !important;
      padding: 0px !important;

      &:after {
        background-color: #f5f5f5 !important;
        color: #7a7a7a;
        font-size: 12px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
      }
    }

    .slider {
      position: relative;
      height: 30px !important;
      top: -10px !important;

      label {
        position: relative;
        top: 4px !important;
      }

      .slider-input {
        position: relative;
        top: 5px;
        margin-left: 5px;
        font-size: 10px;
        width: 9ch;
      }
    }

    .field {
      input {
        max-width: 183px !important;
      }
      textarea {
        max-width: 183px !important;
      }

      .checkbox-input {
        position: relative;
        top: 5px;
      }

      .select_control {
        span {
          max-width: 183px;
        }

        i.fa-question-circle {
          position: relative;
          left: 7.5px;
        }
      }

      i.fa-question-circle {
        position: relative;
        left: 7.5px;
        top: 5px;
        color: #ccc;
        font-size: 14px !important;

        &:hover {
          color: #4a4a4a;
        }
      }
    }

    .date-select {
      font-size: 14px;
      min-width: 200px !important;
    }

    .signature_field {
      margin-left: 15px;
      margin-right: 15px;
    }

    .tags_input input {
      min-width: 150px;
    }

    .delete_button {
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 10px;
      position: relative;
      top: -10px;
    }

    .react-tags-wrapper {
      background-color: #fff;
      border: 1px solid #dadada;
      border-radius: 4px;
      width: 183px;

      input {
        border: 0px;
        padding: 10px;
        font-size: 12px;
      }

      .removeClass {
        margin-left: 5px;
      }

      .tag {
        margin: 5px;
        margin-left: 10px;
        align-items: center;
        background-color: #f5f5f5;
        border-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        color: #4a4a4a;
        display: inline-flex;
        font-size: 0.75rem;
        height: 2em;
        justify-content: center;
        line-height: 1.5;
        padding-left: 0.75em;
        padding-right: 0.75em;
        white-space: nowrap;
      }
    }

    input::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #878787 !important;
      font-size: 12px;
    }

    input::-moz-placeholder {
      /* Firefox 19+ */
      color: #878787 !important;
      font-size: 12px;
    }

    input:-ms-input-placeholder {
      /* IE 10+ */
      color: #878787 !important;
      font-size: 12px;
    }

    input:-moz-placeholder {
      /* Firefox 18- */
      color: #878787 !important;
      font-size: 12px;
    }

    p.doc {
      i {
        margin-right: 5px;
      }
    }
  }
}
