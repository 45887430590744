@import 'constants/css/variables.scss';

.page_container {
  display: flex;
  margin: 0px;
  padding: 0px;
}

.sidebar-column {
  height: 100vh;
  flex: 0 0 auto;
  background-image: linear-gradient(to right, #051d39, #1e344d);

  flex-basis: 16.65%;
  max-width: 210px;

  .sidebar_component {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;

    max-width: 100%;
    z-index: 100;
    .active_page {
      border-left: 3px solid #fff;
      span {
        position: relative;
        left: -3px;
      }
      color: #fff !important;
      cursor: pointer !important;
      background-color: rgba(0, 0, 0, 0.2);
    }

    svg {
      text-align: center;
      position: relative;
      left: 45%;
      margin-top: 30px;
      margin-bottom: 5px;
      fill: #ffffff;
    }

    h2 {
      text-align: center;
      color: #fff;
      font-size: 24px;
      margin-bottom: 10px;
    }

    .breaker {
      position: relative;
      top: 30px;
      border-bottom: 1px solid #364a5e;
      margin-bottom: 20px;
    }

    .sidebar_links {
      cursor: pointer !important;

      .sidebar_logo_container {
        max-width: 100%;
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        img {
          max-width: 75%;
        }
      }
    }
    a {
      color: #fff;
      font-size: 14px;
      margin-left: 20px;
      font-weight: 500;
      cursor: pointer !important;

      &:hover {
        color: #fff;
      }

      .menu-item {
        padding: 20px !important;
        padding-left: 15px !important;
        margin-bottom: -30px;
        cursor: pointer !important;

        i {
          margin-left: 10px;
          margin-right: 10px;
        }
        .toggle-angle {
          position: relative;
          left: 40%;
        }
      }
      .sub-menu {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: -30px;
        .active_page {
          span {
            margin-left: -10px;
          }
        }
        .menu-sub-item {
          position: relative;
          padding: 10px;
          i {
            font-size: 10px;

            margin-right: 10px;
          }
          span {
            position: relative;

            left: -2px;
          }
        }
        .active_page {
          i.fa-circle {
            position: relative;
            left: 2px;
            margin-left: 5px;
          }
        }
      }
    }
    .sidebar_footer {
      border-top: 1px solid #364a5e;
      .user_icon {
        visibility: hidden;
      }
      .dropdown {
        z-index: 9999;
        position: relative;
        padding-top: 15px;
        padding-bottom: 15px;
        .icon {
          margin-left: 5px;
        }
      }
      .dropdown-menu {
        z-index: 99999;
        position: relative;
      }
      .dropdown-content {
        position: absolute;
        z-index: 99999999999 !important;
        min-width: 200px;
        left: 40px;
        bottom: 10px;

        a {
          position: relative;
          left: -20px;
          color: #051d39;
          &:hover {
            color: #051d39;
          }
        }
      }
    }
  }
}

.page_content {
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-basis: auto;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll !important;

  .action_button {
    .icon {
      min-width: 30px;
      i {
        margin-right: 0px;
      }
    }
  }

  .bottom_shadow {
    box-shadow: rgba(0, 0, 0, 0.13) 0px 2px 6px 0px;
  }

  .bottom_border {
    border-bottom: 1px solid #eee !important;
  }
  .top_border {
    border-top: 1px solid #eee !important;
  }

  .filter_nav {
    position: sticky;
    top: 65px;
    font-size: 14px;
    font-weight: 500;
    min-height: 60px;
    .navbar-start {
      margin-left: 20px;
    }
    .navbar-end {
      margin-right: 20px;
    }
    input {
      font-size: 14px;
    }
  }
  .page_nav {
    position: sticky;
    top: 0;
    font-size: 14px;
    font-weight: 500;
    min-height: 65px;
    .navbar-start {
      margin-left: 20px;
    }
    .navbar-end {
      margin-right: 20px;
    }

    .navbar-item {
      a {
        color: $primary;
      }
    }
  }
  .page_scrollable {
    padding-left: 20px;
    padding-right: 20px;
  }
}
