@import 'constants/css/variables.scss';

.onboarding {
  margin-top: -60px;
  font-size: 14px;

  min-height: 200px !important;

  .tasks {
    min-height: 200px;

    i {
      font-size: 20px !important;
      color: #dadada;
      margin-right: 10px;
    }

    ul {
      li {
        padding-bottom: 10px;
        cursor: pointer;
        padding-top: 5px;

        span {
          position: relative;
          top: 5px;
        }
      }

      .is-active {
        font-weight: 600;
        border-right: 5px solid #3298DC;
      }
    }

    .complete {
      color: #48C774 !important;
    }
  }

  .description {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
    margin-right: -20px !important;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: #fafafa;
    height: 240px;
    overflow: hidden !important;

    .big-icon {
      position: relative;
      color: #eee;
    }

    i.fa-stream {
      font-size: 200px;
      left: 50px;
    }

    i.fa-file {
      font-size: 250px;
      left: 50px;
    }

    i.fa-history {
      font-size: 250px;
      left: 50px;
    }

    .instruction {
      padding-top: 30px;
      padding-left: 20px;
      line-height: 30px;

      &:not(.is-active) {
        display: none;
      }

      button {
        position: absolute;
        bottom: 30px;
        font-size: 14px;
        box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
      }

      .dzu-dropzone {
        max-width: 650px;
        height: 200px;
      }
    }
  }
}