@import 'constants/css/styles';

.rectangle_annotation {
  background-color: rgba(70, 182, 226, 0.4);
  border: 2px solid rgba(70, 182, 226, 0.4);
  box-shadow: 0px 0px 1px 1px white inset;
  box-sizing: border-box;
  transition: box-shadow 0.21s ease-in-out;
  display: inline-flex;
  // cursor: pointer !important;
  z-index: 1000;

  .rectangle_anchor {
    width: 5px;
    height: 5px;
    background-color: rgba(70, 182, 226, 0.4);
    border: 2px solid rgba(70, 182, 226, 0.4);
  }
}

.rectangle_annotation_comb {
  display: grid;
  grid-auto-flow: column;
  min-width: 100%;
}

.rectangle_annotation_comb_box {
  border: 1px solid #3284a8;
}

.rectangle_annotation_label {
  //   display: flex;
  //   align-self: center;
  margin-left: 5px;
  user-select: none;
  //   color: #0F424F;
}
.rectangle_top_name {
  cursor: pointer;
  background-color: rgba(70, 182, 226, 0.8);
  color: #fff;
  position: absolute;
  // top: -22px;
  left: -2px;
  font-size: 10px;
  font-weight: 500;
  padding: 2px;
  i {
    margin-left: 2px;
    color: #fff !important;
  }
  span {
    padding-right: 5px;
    span:last-child {
      display: none;
    }
  }

  border-left: 2px solid rgba(70, 182, 226, 1);
  border-left: 1px solid rgba(70, 182, 226, 1);
}
