.folder_select_button {
  padding-top: 19px;
  padding-bottom: 19px;
  i {
    font-size: 14px;
    margin-left: 5px;
  }
}
.sv_folder_picker_modal {
  .loader_container {
    margin-top: 25px;
    text-align: center;
  }
}
