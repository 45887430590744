.form_library_modal {
  top: 0vh !important;
  max-width: 75vw !important;
  width: 100% !important;
  .title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #000;
    margin-bottom: 1rem;
  }
  .templates_grid {
    max-height: 80vh;
    margin-left: -15px;
    overflow-y: scroll;
    .image {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .subtitle {
      margin-top: 5px;
      margin-bottom: 10px;
    }
    .columns {
      margin-top: -15px;
    }
    .tag {
      margin-right: 5px;
      margin-bottom: 10px;
      font-size: 11px;
    }
    .use_template {
      font-size: 14px;
      background-color: #2793da;
      color: #fff;
    }
  }
  .templates_sidebar {
    li,
    p {
      font-size: 14px;
    }
    .menu-label {
      text-transform: none;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: normal;
    }
    .menu-list a.is-active {
      background-color: #2793da;
      color: #fff;
    }
  }
}
