@import 'constants/css/styles.scss';

$border-color: #dadada;
$hover-color: #b5b5b5;
$background-color: #f5f5f5;
$accent-color: #3298dc;
$icon-size: 16px;

.drawer {
  min-width: 60px;
  display: flex;
  position: absolute;
  right: 0px;
  background-color: #fff;
  height: $document_editor_height;
  z-index: 9999900;

  // flex-direction: column;
  &:not(.is-closed) {
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.2),
      0 0px 0 1px rgba(10, 10, 10, 0.02);
  }

  .drawer_tabs {
    flex: 20%;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    height: 100%;
  }

  .tab {
    padding: 10px;

    i {
      cursor: pointer;
      font-size: $icon-size;
      padding: 10px;
      border: 1px solid $border-color;
      background-color: $background-color;
      border-radius: 10px;

      &:hover {
        background-color: $hover-color;
      }
    }

    .is-closed {
      display: none;
    }
  }

  .active-tab i {
    background-color: $accent-color;
    color: #fff;
    border: 1px solid $accent-color;

    &:hover {
      background-color: $accent-color;
    }
  }

  aside {
    flex: 80%;
    box-sizing: border-box;
    max-width: 400px;
    min-width: 400px;
    height: 100%;

    .header {
      height: 50px;
      padding: 10px;
      background-color: $background-color;
      border-bottom: 1px solid $border-color;

      .close {
        cursor: pointer;
        color: #969ea2;
      }
    }

    .component {
      padding: 10px;
    }
  }
}
