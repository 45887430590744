@import 'constants/css/styles';

.document_editor_container {
  // margin-top: 105px !important;
  position: absolute;
  top: 105px;
  overflow: hidden;
  z-index: 1;
  width: calc(100vw);
  height: $document_editor_height;

  .field_editor *,
  .fields_panel *,
  .tabs,
  .toolbar * {
    font-size: 12px !important;
  }
}

.document_editor {

  .toolbar * {
    font-size: 12px !important;
  }

}

.document_editor_loader_container {
  position: absolute;
  top: 40vh;
  left: 50%;

  span {
    position: relative;
    font-size: 14px;
    top: 10% !important;
    left: -40% !important;
  }
}

.replace-document-btn-wrapper {
  margin-top: 16px;
  height: 50px;
  width: 100%;
}

.replace-btn {
  margin-bottom: 16px;
}