@import 'constants/css/styles.scss';

.dashboard-title {
  font-size: 20px !important;
  margin-left: 20px;
}
.onboarding-wrapper .container {
  margin-top: -40px !important;
}
.dashboard {
  background-color: $page_background;
  min-height: $page_min_height;

  .dashboard_loader_container {
    text-align: center;
    margin-top: 100px;
  }

  .header h3 {
    margin-top: 10px;
  }

  .documents {
    margin-top: -40px;
    background-color: #fefefe !important; //#E3E8EE
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .container {
      justify-self: center;
      .document_card:not(:first-child) {
        margin-left: 20px !important;
      }
    }
  }

  .recent_workflows {
    h3 {
      font-size: 20px !important;
      color: #1951a3;
      margin-bottom: 50px;
    }
  }
}
