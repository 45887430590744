@import 'constants/css/styles.scss';

.change_password,
.change_email {
  .modal-content {
    max-width: 500px;
  }
}

.change_email {
  font-size: 14px;

  .field {
    margin-bottom: 20px;
  }
}

.settings_page {
  background-color: $page_background !important;
  min-height: $page_min_height;
  .fullwidth_loader_container {
    text-align: center;
    margin-top: 200px;
  }

  .page-box {
    margin-top: 20px;
    min-height: 500px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .tabs {
    font-size: 14px !important;
  }

  .message {
    font-size: 14px;
  }
  .menu {
    a {
      font-size: 14px;
      padding-top: 15px;
      padding-bottom: 15px;
      border-radius: 0px;
      &:hover {
        margin-left: -5px;
        border-left: 5px solid #2793da !important;
      }
    }
    .is-active {
      color: #363636;
      border-left: 5px solid #2793da !important;
      background-color: #f5f5f5;
      margin-left: -5px;
    }
  }
  .profile_form {
    // max-width: 400px;

    position: relative;
    // left: 50%;
    // margin-right: -50%;
    // transform: translate(-50%, 0%);

    .label {
      // margin-top: 10px;
      font-weight: 700 !important;
    }

    .change_password {
      font-size: 14px;
    }
    .update_name {
      position: relative;
      font-size: 14px;
      top: -20px;
    }

    button {
      margin-top: 20px;
    }
  }

  .billing {
    font-size: 14px !important;

    p {
      margin-bottom: 10px;
    }

    .button {
      font-size: 14px !important;
    }

    .plans {
      .columns {
        text-align: center;
        margin-top: 20px;

        .column {
          border: 1px solid #cccc;
          margin: 10px;

          p {
            margin-bottom: 10px;
            font-weight: 500;
          }
        }
      }
    }
  }

  .billing_loader_container {
    text-align: center;
    margin-top: 100px;
  }

  h4 {
    font-size: 20px !important;
  }

  input,
  label,
  button {
    font-size: 14px !important;
  }

  .apps {
    font-size: 14px !important;

    img {
      height: 40px;
      width: 40px;
    }

    i {
      margin-right: 10px;
    }

    .empty {
      min-height: 200px !important;
    }
  }
}

.invite_member_modal,
.edit_member_modal,
.modal_top {
  font-size: 14px;
  max-height: 50vh;
  input,
  select,
  button {
    font-size: 14px !important;
  }
}
.react-responsive-modal-modal {
  overflow-y: visible !important;
  .employee_select {
    z-index: 5;
    word-wrap: break-word;
  }
}
.members {
  p {
    font-size: 14px;
  }
  .members_description {
    margin-top: 10px !important;
    margin-bottom: 10px;
  }
  .members_table {
    thead {
      font-size: 14px;
    }
    tbody {
      font-size: 13px;
    }
    .table td,
    .table th {
      border: 0px solid #dbdbdb;
      border-width: 0 0 1px;
      padding: 0.5em 0.75em;
      vertical-align: top;
      padding-top: 15px;
      padding-bottom: 10px;
    }
  }
}
.brand_description {
  margin-bottom: 30px !important;
}
.brand_options {
  .label {
    font-weight: 700 !important;
  }
  .brand_button_row {
    margin-top: 10px;
    min-width: 100%;
    .icon {
      position: relative;
      left: 5px;
    }
  }
  .image_field {
    background-color: #f2f4f7;
    padding: 20px;
    border-radius: 6px;
  }
  .color_swatches {
    background-color: #f2f4f7;
    display: flex;
    padding: 20px;
    border-radius: 6px;
    .color_swatch {
      &:nth-child(2) {
        margin-left: 20px;
      }
      display: inline-block;
      .label {
        position: relative;
        font-weight: 500 !important;
        margin-bottom: -15px;
      }
      .color_block {
        position: relative;
      }
      .chrome_picker {
        position: 'relative';
        top: '0px';
        right: '0px';
        bottom: '0px';
        left: '0px';
      }
    }
  }
}
