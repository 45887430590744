@import 'constants/css/styles.scss';

.processing_document {
  height: 270px !important;
  // padding: 10px;
}

.card {
  width: 270px;
  border-radius: 4px;

  //border: 1px solid #fff;
  &:hover {
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.2),
      0 0px 0 1px rgba(25, 81, 163, 0.02) !important;
    //   border: 1px solid $primary;
    // border: 1px solid #d6ddea;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
    -webkit-box-shadow: 0 8px 10px -4px rgba(93, 96, 99, 0.08),
      0 1px 2px 0 rgba(71, 80, 89, 0.03);
    box-shadow: 0 8px 10px -4px rgba(71, 80, 89, 0.08),
      0 1px 2px 0 rgba(71, 80, 89, 0.03);
  }

  .card-image {
    overflow: hidden !important;
    cursor: pointer;

    img {
      min-height: 180% !important;
      background-position: center center;
      background-repeat: no-repeat;
      overflow: hidden;
      object-fit: cover;
    }
  }

  .card_edit {
    font-size: 12px;
  }

  .card_delete {
    font-size: 12px;
  }

  i {
    margin-right: 10px;
  }

  .card-content {
    font-size: 14px;
    // margin-left: -10px;
    .fa-file-pdf {
      color: #a50000;
      // margin-right: 5px;
    }
    border-top: 1px solid #eaeaea;
  }

  .dropdown {
    position: relative;
    left: 70px;
    button {
      max-width: 40px;
    }

    .dropdown-item {
      font-size: 12px;
    }

    i {
      position: relative;
      left: 5px;
    }
  }

  .level {
    margin-top: -20px;
    width: 160px;
  }

  .updated_date {
    font-size: 12px !important;
  }
}
