@import 'constants/css/styles.scss';
.document_details {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 40px;
  h5 {
    font-size: 14px !important;
    font-weight: 700;
  }
  .value_image {
    max-width: 300px;
    padding: 10px;
  }
  .signature_image {
    max-width: 300px;
    padding: 10px;
  }

  .document-info {
    margin-top: -20px;
    margin-bottom: 20px;
    td {
      min-width: 165px !important;
      padding: 3px;
      &:first-child {
        font-weight: 700;
      }
    }
  }
  span.status {
    margin-left: -7px;
  }
  .document-history {
    tr {
      min-height: 50px;
    }
    td {
      min-width: 150px;
      padding: 3px;
      &:first-child {
        font-weight: 700;
      }
    }
    .field-headers {
      font-weight: 700;
      td {
        min-width: 150px;
      }
    }
  }
}
.historyPage {
  background-color: $page_background;
  min-height: $page_min_height;

  .search input {
    font-size: 14px !important;
  }

  .loader_container {
    text-align: center;
  }
}
.document_page {
  .button {
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
      rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
  }
}
.workflow_history_page {
  .log-button {
    width: 128px !important;
  }
  .history_table {
    margin-top: -35px;
    font-size: 14px !important;

    tbody tr {
      cursor: pointer;
    }

    .filters {
      margin-left: -30px;
      position: relative;
      top: -30px;

      .select {
        // margin-left: -20px !important;
        margin-left: 10px;
        margin-bottom: 10px;
      }
    }
  }

  .pagination {
    margin-top: 10px;
    font-size: 14px !important;
  }
}
.history_run_container {
  background-color: $page_background;
  min-height: $page_min_height;

  .schema-container {
    padding: 10px;
    background-color: #fff;
    // border: 1px solid #dadada;
    border-radius: 6px;
    font-family: 'Courier New', Courier, monospace;

    .schema-key {
      color: #000;
      font-weight: 700;
      color: #1a314a;
    }

    .schema-value {
      color: #ce5628;
      display: inline-block;
      height: 100%;
      vertical-align: middle;

      img {
        vertical-align: middle;
      }

      a {
        color: #000;
        text-decoration: underline;
      }
    }
  }

  .history_run {
    font-size: 14px !important;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
    background-color: $page_background;
    min-height: $page_min_height;

    .tabs {
      font-size: 14px;
    }
    img {
      max-height: 90px;
    }
  }

  .loader_container {
    position: absolute;
    top: 40vh;
    left: 50%;
  }
}

h4 {
  font-size: 20px !important;
  color: #1951a3 !important;
}

.status .fa-check-circle {
  color: #48c774 !important;
  font-size: 14px !important;
}

.status .fa-times-circle {
  color: #f14668 !important;
  font-size: 14px !important;
}

.status .fa-clock {
  color: #3298dc !important;
  font-size: 14px !important;
}
.status .fa-ban {
  color: #c7c8c9 !important;
  font-size: 14px !important;
}

.step_run_date {
  position: relative;
  top: -10px;
}

.historyPage {
  .documents_table {
    margin-top: -30px;
    .history_table {
      margin-top: 0px;
      .rdt_TableHeader {
        display: none;
      }
      .rdt_TableCol {
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
  .box {
    min-height: 400px;
    .loader_container {
      height: 400px;

      svg {
        position: relative;
        top: 150px;
      }
    }
  }
  .filters {
    margin-top: -20px;
    margin-bottom: 10px;
  }
  .dropdown_filter {
    font-size: 14px;
  }
}

.filter-box {
  display: flex;
  flex-direction: row;
}

.delete-button {
  color: #000;
  padding-top: 16px;
  padding-bottom: 16px;
}

.document_history_page {
  .fa-file-pdf {
    color: #a50000;
    font-size: 14px !important;
    margin-right: 20px;
  }
}
.action_button_white {
  font-size: 14px;
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
}
