/*--------[Common for All]--------*/
/*###########################################################################*/
/*--------[Desktop Only]--------*/
/*###########################################################################*/
@media only screen and (min-width: 1180px) {
  .sidebar-column {
    min-width: 210px !important;
  }
}

/*###########################################################################*/
/*----[Mobile Only}----*/
/*###########################################################################*/
@media only screen and (max-width: 768px) {
  .sidebar_component {
    max-width: 120px !important;
    svg {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: -10px;
    }

    h2 {
      visibility: hidden;
    }
    .user_icon {
      visibility: visible !important;
      text-align: center;
    }
    .sidebar_footer_text {
      display: none;
    }
    .angle_icon {
      display: none;
    }
    .menu-item span {
      i {
        visibility: visible !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      visibility: hidden;
    }
  }
}

/*###########################################################################*/
/*--------[Large Tablets and small monitors]--------*/
/*###########################################################################*/
@media only screen and (min-width: 980px) and (max-width: 1180px) {
  .sidebar-column {
    min-width: 210px !important;
  }
}

/*###########################################################################*/
/*--------[Tablets and Landscape]--------*/
/*###########################################################################*/
@media only screen and (min-width: 768px) and (max-width: 980px) {
  .document_card {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px !important;
  }
  .sidebar-column {
    max-width: 120px !important;
  }
  .sidebar_component {
    h2 {
      visibility: hidden;
    }
    .user_icon {
      visibility: visible !important;
      text-align: center;
    }
    .sidebar_footer_text {
      display: none;
    }
    .angle_icon {
      display: none;
    }

    .menu-item span {
      i {
        visibility: visible !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      visibility: hidden;
    }
  }
}

/*###########################################################################*/
/*--------[Phablets and Phone Landscape]--------*/
/*###########################################################################*/
@media only screen and (min-width: 479px) and (max-width: 767px) {
  .sidebar-column {
    width: 120px !important;
  }
  .login {
    .column {
      min-width: 85% !important;
    }
  }
}

/*###########################################################################*/
/*--------[Normal Phones]--------*/
/*###########################################################################*/
@media only screen and (max-width: 479px) {
  .login {
    .column {
      min-width: 95% !important;
    }
  }

  .dashboard {
    .messageBar {
      display: none;
    }

    .big-icon {
      &::before {
        display: none !important;
      }
    }

    .onboarding {
      button {
        font-size: 12px !important;
      }
    }

    .documents .document_card {
      margin-bottom: 30px !important;
      position: relative;
      left: -20px !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .workflow_box {
      max-width: 300px !important;

      .workflow_name span {
        background-color: #fff;
        padding: 3px;
        border-radius: 6px;
        border: 1px solid #dadada;
        font-weight: 500;
      }

      .level-right {
        display: none;
      }
    }
  }

  .page_scrollable .workflows {
    .box {
      min-width: 100px !important;
    }

    .workflow_box {
      .workflow_name span {
        background-color: #fff;
        padding: 3px;
        border-radius: 6px;
        border: 1px solid #dadada;
        font-weight: 500;
      }

      .level-right {
        display: none;
      }
    }
  }

  .user_documents {
    .document_card {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .historyPage {
    nav.pagination {
      margin-top: 10px;
    }

    .search {
      display: none;
    }

    .history_table {
      .filters {
        display: none;
      }
    }
  }

  .settings_page {
    .container,
    .columns {
      max-width: 85% !important;
    }

    .page-box {
      margin-bottom: 20px;
    }
  }

  .page {
    min-height: 100vh;

    h3.title {
      font-size: 20px !important;
    }

    .filters {
      input,
      span {
        display: none;
      }
    }

    .level-right {
      button {
        font-size: 12px;
      }
    }

    .footer {
      position: absolute;
      bottom: 0;
    }
  }

  .navbar {
    .navbar-burger {
      color: #fff;
    }

    .navbar-menu {
      background-color: #1e344d !important;
    }

    .active_page {
      border-bottom: 0px;
    }

    .navbar-end {
      display: none;
    }
  }

  .document_editor {
    .tabs {
      display: none;
    }
  }

  .workflow_editor_container {
    .media-right {
      .dropdown-trigger {
        display: none;
      }
    }
  }
}

.trigger_document_form {
  .signature_clear {
    color: #fff !important;
    font-weight: 500;
    max-width: 240px;
  }
}

// For adjusting tables: https://stackoverflow.com/a/56713791
@media only screen and (max-width: 1050px) {
  .historyPage table,
  .historyPage thead,
  .historyPage tbody,
  .historyPage th,
  .historyPage td,
  .historyPage tr {
    display: block;
  }

  .historyPage thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .historyPage tr {
    border: 1px solid #ccc;
  }

  .historyPage td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 200px;
    margin-left: 150px;
  }

  .historyPage td:before {
    position: absolute;
    top: 12px;
    left: 6px;
    width: 200px;
    padding-right: 40px;
    white-space: nowrap;
    margin-left: -150px;
  }

  .historyPage td:nth-of-type(1):before {
    content: 'Option';
  }

  .historyPage td:nth-of-type(2):before {
    content: 'Description';
  }

  .historyPage d:nth-of-type(3):before {
    content: 'Type';
  }

  .historyPage td:nth-of-type(4):before {
    content: 'Default';
  }
}
