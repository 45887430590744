.trigger_form_wrapper {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  background-color: #fafafa;

  .group_field {
    margin-top: 30px;
    & > label {
      font-size: 15px !important;
    }
    .description {
      margin-bottom: 10px;
    }
    .label_hr {
      margin-top: 10px;
    }
    .fields_hr {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .fields {
      margin-left: 10px;
    }
  }

  .form_auth {
    margin: 40px;
  }
  .form_cover {
    min-height: 200px;
    background-color: #182838;
  }
  .form-wrapper {
    background-color: #fafafa;
    border-radius: 8px !important;
  }
  .form-header {
    border-radius: 8px !important;
    // box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.2),
    //   0 0px 0 1px rgba(25, 81, 163, 0.02) !important;
    // -webkit-box-shadow: none;
    // box-shadow: none;
    // -webkit-transition: -webkit-box-shadow 0.3s;
    // transition: -webkit-box-shadow 0.3s;
    // transition: box-shadow 0.3s;
    // transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
    // -webkit-box-shadow: 0 8px 10px -4px rgba(93, 96, 99, 0.08),
    //   0 1px 2px 0 rgba(71, 80, 89, 0.03);
    // box-shadow: 0 8px 10px -4px rgba(71, 80, 89, 0.08),
    //   0 1px 2px 0 rgba(71, 80, 89, 0.03);
  }
  .title_description {
    background-color: #fff;
    font-weight: 700;
    color: #333333;
    margin-top: -80px;
    border-radius: 8px !important;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 40px;
    padding-bottom: 20px;
    h1 {
      font-weight: 700 !important;
    }
    .form_logo {
      max-height: 100px;
      margin-bottom: 20px;
    }
  }
  .html_description {
    background-color: #fff;
    min-width: 100% !important;
    margin-top: -30px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    padding-bottom: 40px;
    margin-bottom: 20px;
    border-radius: 8px !important;
    p {
      font-size: 18px;
    }
  }
  .view_document {
    margin-left: 10px;
    a {
      font-weight: 600;
      color: #2d7ff9;
      font-size: 18px;
    }
    .view_document_container {
      margin-left: 5px;
      margin-top: 10px;
      max-height: 60vh;
      overflow-y: scroll;
      border: 1px solid #dadada;
      background-color: #fff;
      .document_preview_loading {
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        justify-content: center;
      }
      img {
        margin: 0px;
        border-bottom: 1px solid #dadada;
        border-left: 1px solid #dadada4d;
        border-right: 1px solid #dadada4d;
        border-top: 1px solid #dadada4d;
      }
    }
  }
  .columns {
    min-height: calc(100vh - 270px);
  }

  .loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #fff;
    opacity: 0;
    z-index: -1;
    transition: opacity 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;

    .loader {
      height: 80px;
      width: 80px;
    }

    &.is-active {
      opacity: 1;
      z-index: 1;
    }
  }

  .form_fields {
    margin: 20px;
    .field {
      .label {
        color: #333333 !important;
        font-size: 15px;
        font-weight: 600 !important;
      }
    }
    input:not(.signature-type) {
      font-size: 15px;
    }

    label::before,
    .type-sign {
      background-color: #fff;
    }
    .help {
      margin-top: 10px;
      font-size: 15px;
    }
    .signature-upload {
      .field {
        margin-bottom: 0px;
      }
    }
    .signature_consent {
      margin-top: 0px;
    }
    .auth_none {
      .signature_consent {
        margin-top: 0px;
      }
    }
  }

  .submit-success {
    margin: 40px;
  }

  .button {
    background-color: #2d7ff9 !important;
    font-size: 15px !important;
    padding: 20px;
    border-radius: 4px;
  }

  .label,
  .control label,
  .input {
    font-size: 14px;
  }

  .display_form {
    padding-top: 10px;
    min-width: 600px;
    // max-height: 100% !important;
    overflow-y: scroll;
  }

  .docdown_footer_credits {
    display: none;
    padding: 20px;
    background-color: #fff;
    font-size: 12px;
    color: #0a0a0a;
  }
  .signature_clear {
    background-color: #fff !important;
    i {
      color: #182838 !important;
    }
  }
}
.form_not_found {
  .content {
    background-color: #fff;
  }
  min-height: 100vh;
}
.trigger_document_form {
  .column {
    clear: both;
    padding-top: 20px;
  }

  .rdtPicker {
    bottom: 0;
    position: relative;
  }

  .docdown_footer_credits p {
    margin-bottom: 10px !important;
  }
}

.trigger_split_form {
  // margin-top: 105px !important;
  position: absolute;
  overflow: hidden;
  z-index: 1;
  width: calc(100vw);
  height: 100%;

  .form-wrapper {
    padding-bottom: 20px;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    min-height: calc(100vh - 50px);
    .form_header {
      position: sticky;
      top: -10px;
      z-index: 9999;
      margin-top: -10px;
      .title_description {
        margin-top: 0px;
        margin-right: -10px;
        border-collapse: separate;
        border-radius: 0px !important;
        .form_logo {
          max-height: 100px;
          margin-bottom: 20px;
        }
      }
      .html_description {
        margin-right: -10px;
        min-width: 100% !important;
        border-radius: 0px !important;
        border-bottom: 1px solid #dadada4d;
      }
    }
  }
  .docdown_footer_credits {
    margin-top: -20px;
    margin-bottom: -20px;
    margin-right: -10px;
    border-top: 1px solid #dadada4d;
  }

  .form_fields {
    margin-left: 40px;
    margin-right: 40px;
  }

  .display_document_container {
    padding-top: 10px;
    min-width: 600px;
    // max-height: 100% !important;
    overflow-y: scroll;
    border-left: 1px solid #dadada;
    .public_document_preview {
      border-left: 0px;

      img {
        margin: 0px;
        border-bottom: 1px solid #dadada;
        border-left: 1px solid #dadada4d;
        border-right: 1px solid #dadada4d;
        border-top: 1px solid #dadada4d;
      }

      .document_preview_loading {
        position: relative;
        left: 50%;
        top: 200px;
      }

      .undefined {
        div {
          position: relative;
          top: -2px;
          color: #3284a8 !important;
          font-size: 12px !important;
        }
      }

      .page {
        // margin-top: 20px;

        .react-pdf__Page__canvas {
          display: block;
          margin: 0 auto;
        }
      }
    }
  }
}

/*--------[Normal Phones]--------*/
@media only screen and (max-width: 540px) {
  .trigger_form_wrapper {
    .title_description {
      background-color: #fff;
      font-weight: 700;
      color: #333333;
      margin-top: -140px;
      border-radius: 8px !important;
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 40px;
      padding-bottom: 20px;
      h1 {
        font-weight: 700 !important;
      }
      .form_logo {
        max-height: 100px;
        margin-bottom: 20px;
      }
    }
    .html_description {
      background-color: #fff;
      min-width: 100% !important;
      margin-top: -30px;
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 20px;
      padding-bottom: 40px;
      margin-bottom: 20px;
      border-radius: 8px !important;
      p {
        font-size: 18px;
      }
    }
    .form_fields .signature_consent {
        margin-top: -20px;
        margin-bottom: 30px;
      }
  }
}
