.ellipsis_dropdown {
  .dropdown-trigger button {
    width: 40px;
    height: 30px;
  }
  .dropdown-content {
    i {
      width: 10px;
      margin-right: 5px;
    }
  }
  .dropdown-item {
    padding-right: 3rem;
    text-align: left;
    white-space: nowrap;
    width: 100%;
    &:hover {
      background-color: whitesmoke;
      color: #0a0a0a;
      cursor: pointer;
    }
  }
}
